<template>
    <div>
         <Head :title="'厂内调度申请'" :out="'ProcessTask?active=1'" />
        <div class="content">
            <van-form  label-width="150">
                <van-field :readonly="dataInfo.status!=-1" v-model="dataInfo.start_position" label="起始工位" placeholder="请输入起始工位" />
                <van-field :readonly="dataInfo.status!=-1" v-model="dataInfo.end_position" label="送达工位" placeholder="请输入送达工位" />
                <van-field readonly v-model="dataInfo.defined_num" label="自定义车辆编号" placeholder="请输入自定义车辆编号" />
                <van-field readonly v-model="dataInfo.carNumberPlate" label="车牌号码" placeholder="请输入车牌号码" />
                <van-field readonly v-model="dataInfo.vin" label="车架号码" placeholder="请输入车架号码" />
                <p class="box_img">
                    <span>进厂45度接车图片</span>
                    <van-image width="100%" height="100%" :src="dataInfo.vehicleImages0?dataInfo.vehicleImages0:imgs" />
                </p>
                <van-field readonly v-if="dataInfo.status!=-1" v-model="dataInfo.createTime" label="申请时间" placeholder="请输入申请时间" />
                <van-field readonly v-if="dataInfo.status!=-1" v-model="dataInfo.StatusText" label="当前状态" placeholder="请输入当前状态" />
                <van-field readonly v-if="dataInfo.status >=3 " v-model="dataInfo.realName" label="下单员工" placeholder="请输入下单员工" />
                <van-field readonly v-if="dataInfo.status >=3 " v-model="dataInfo.phone" label="联系电话" placeholder="请输入联系电话" />
                <van-field readonly v-if="dataInfo.status==9" v-model="dataInfo.end_time" label="完成时间" placeholder="请输入联系电话" />
                <div style="margin: 16px;">
                    <van-button v-if="dataInfo.status==-1" round block type="info" @click="onSubmit">提交</van-button>
                    <van-button v-if="dataInfo.status== 6 " round block type="info" @click="songOk" >确认送达</van-button>
                </div>
                </van-form>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { forkliftFindCar,forkliftTask,forkliftConfirm } from '@/api/gong'
import { Dialog } from 'vant'
    export default {
        components: {
            Head,
        },
        data() {
            return {
                dataInfo: {
                    defined_num:"",
                    carNumberPlate:"",
                    start_position:"",
                    end_position:"",
                    vin:"",
                    status:0,
                    vehicleImages0:'',
                },
                 imgs:require('../../assets/zw.jpg')
            }
        },
        mounted () {
            this.lodData();
        },
        computed: {
            id() {
                return this.$route.query.id
            }
        },
        methods: {
            async lodData() {
                const {data} = await forkliftFindCar({id:this.id}).then(res=>res)
             
                this.dataInfo = data.data
            },
            async songOk(){
                 Dialog.confirm({
                    title: '提示',
                    message: '是否确认送达？',
                    })
                    .then(async() => {
                    try{
                        const {data} = await forkliftConfirm({id:this.id}).then(res=>res)
                        this.$toast.success(data.msg)
                        this.lodData();
                        }
                    catch{}
                })
                .catch(() => {});
                
            },
            //确定
             onSubmit(){
                    Dialog.confirm({
                    title: '提示',
                    message: '提交后不能修改！',
                    })
                    .then(async() => {
                        try{
                         let obj = {
                                id:this.id,
                                start_position:this.dataInfo.start_position,
                                end_position:this.dataInfo.end_position,
                            }
                            const {data} = await forkliftTask(obj).then(res=>res)
                            this.$toast.success(data.msg)
                            this.lodData()
                         }
                        catch{}
                    })
                    .catch(() => {});
                   
               
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
}
.box_img{
    padding: 0.2rem 0.32rem;
    span{
        margin-bottom: 10px;
        display: inline-block;
    }
}
</style>